import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip
} from 'chart.js';
import { formatNumber } from '../helpers/NumberHelper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip
);

const options = {
  responsive: true,
  elements: {
    line: {
      tension: 0.2
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      border: {
        display: false,
        dash: [10, 10]
      },
      grid: {
        drawTicks: false,
      },
      ticks: {
        padding: 20,
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: (val: any, index: number) => {
          // Hide every 2nd tick label
          return val === 0 ? 0 : `${formatNumber(val)}`;
        },
        maxTicksLimit: 5
      },
    },
    x: {
      grid: {
        display: false, // Hide the y-axis grid lines and line,
      },
      border: {
        display: false
      }
    },
  },
  plugins: {
    legend: {
      display: false
    },
  },
  maintainAspectRatio: false
};

export const LineChart = ({ data } : { data: any }) => {
  return <Line height={300} options={options} data={data} />
}