import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import '../styles/Stories.css';

import BrandItem from '../components/BrandItem';
import {BrandEntity} from "../entities/brand.entity";
import {BrandService} from "../services/brand.service";
import {useCookies} from "react-cookie";

const breakpointColumnsObj = {
  default: 3, // Number of columns at the default breakpoint
  1100: 2,   // Number of columns at the 1100px breakpoint
  700: 1,    // Number of columns at the 700px breakpoint
};

const Brands = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [brands, setBrands] = useState<BrandEntity[]>([]);

  const fetchData = async () => {
    const data = await BrandService.list();
    setBrands(data);
  }

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  return (
    <Container fluid>
      <Row className='mb-2'>
        <Col>
          <h2 className={'header'}>Brands In Culture</h2>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid-column"
          >
            {brands.map((item, index) => (
              <div key={index} className="grid-item">
                <BrandItem {...item} />
              </div>
            ))}
          </Masonry>
        </Col>
      </Row>
    </Container>
  );
};

export default Brands;
