import React, { useEffect, useMemo, useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import StoryItem from '../components/StoryItem';
import Masonry from 'react-masonry-css';
import { UseTabs } from '../hooks/UseTabs';

import '../styles/Stories.css';
import { sortByYearAndMonth } from '../helpers/ArrayHelper';
import {StoryWithCategory} from "../entities/story.entity";
import {StoryService} from "../services/story.service";
import {CategoryEntity} from "../entities/category.entity";
import {CategoryService} from "../services/category.service";
import {useCookies} from "react-cookie";

const breakpointColumnsObj = {
  default: 3, // Number of columns at the default breakpoint
  1100: 2,   // Number of columns at the 1100px breakpoint
  700: 1,    // Number of columns at the 700px breakpoint
};

const Stories = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [stories, setStories] = useState<StoryWithCategory[]>([]);
  const [categories, setCategories] = useState<CategoryEntity[]>([]);

  const [StoriesTabs, selectedStory] = UseTabs(
    [
      {eventKey: 'all', title:<h2>All</h2>},
      ...categories.map(c => ({eventKey: c.id.toString(), title:<h2>{c.title}</h2>}))
    ]
  )

  const cardData = useMemo(() => {
    if ( selectedStory === 'all') {
      return [...stories]
    }

    return stories.filter(s => s.category.id.toString() === selectedStory)
  }, [stories, selectedStory])

  const fetchData = async () => {
    const stories = await StoryService.list();
    const sortStories = sortByYearAndMonth(stories);
    setStories(sortStories);

    const categories = await CategoryService.list();
    setCategories(categories.filter(c => c.filterable));
  }

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  return (
    <Container fluid className='story'>
      <Row className='stories'>
        <Col>
          {StoriesTabs}
        </Col>
      </Row>
      <Row className="mt-3" style={{ flex: 1, overflow: 'auto' }}>
        <Col>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid-column"
          >
            {cardData.map((item, index) => (
              <div key={index} className="grid-item">
                <StoryItem {...{ ...item, categoryId: item.category.id }} />
              </div>
            ))}
          </Masonry>
        </Col>
      </Row>
    </Container>
  );
};

export default Stories;
