import WebViewer from '@pdftron/pdfjs-express-viewer';
import React, { useEffect, useRef } from "react";
import {pdfJsConstants} from "../common/config";

function ViewPdf({ doc } : { doc: string }) {
  const viewer = useRef<any>(null);
  const instance = useRef<any>(null);
  
  useEffect(() => {
    if (!doc) {
      return;
    }

    if (instance.current) {
      instance.current.loadDocument(doc)
      return;
    }

    WebViewer(
      {
        path: '/',
        licenseKey: pdfJsConstants.licenseKey,
        initialDoc: doc,
        disabledElements: ['downloadButton', 'printButton']
      },
      viewer.current,
    ).then((inst: any) => {
      instance.current = inst
    });
  }, [doc]);

  return <div style={{ height: 'calc(100vh - 56px)', marginBottom: -24 }} ref={viewer}></div>;
}

export default ViewPdf;
