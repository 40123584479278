import React from 'react';
import { Card, Carousel, Col, Row } from 'react-bootstrap';
import {StoryWithCategory} from "../entities/story.entity";
import {monthNames} from "../helpers/DateHelper";
import {apiURL} from "../common/config";

const StoryItem: React.FC<StoryWithCategory> = ({ images, title, whatHappened, whyItMatters, category, year, month }) => {
  return (
    <Card className='shadow'>
      <Carousel 
        interval={null} 
        indicators={false} 
        controls={images.length > 1}
        nextIcon ={<img src={`/assets/icons/right_arrow.png`} alt='Next' />}
        prevIcon={<img src={`/assets/icons/left_arrow.png`} alt='Prev' />}
      >
        {images.sort((a, b) => {
          // @ts-ignore
          return b.isMain - a.isMain;
        }).map((image, index) => (
          <Carousel.Item key={index}>
            {image.linkOut
              ? <a href={image.linkOut} target='_blank' rel="noreferrer">
                <img className="d-block w-100" src={`${apiURL}/image/view/${image.id}`} alt={`Slide ${index}`} />
                <img className="open-link" src={"/assets/icons/open_link.png"} alt='' />
              </a>
              : <img className="d-block w-100" src={`${apiURL}/image/view/${image.id}`} alt={`Slide ${index}`} />
            }
          </Carousel.Item>
        ))}
      </Carousel>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          <Row>
            <Col className='text-orange'>{category.title}</Col>
            <Col className='align-right'>{monthNames[month - 1]}, {year}</Col>
          </Row>
        </Card.Text>
        <Card.Text className='header'>What's happening</Card.Text>
        <Card.Text>{whatHappened}</Card.Text>
        {whyItMatters ? <>
          <Card.Text className='header'>Why it matters</Card.Text>
          <Card.Text>{whyItMatters}</Card.Text>
        </> : null}
      </Card.Body>
    </Card>
  );
};

export default StoryItem;
