import React from 'react';
import { Card, Button } from 'react-bootstrap';
import {NewsletterEntity} from "../entities/newsletter.entity";
import {useCookies} from "react-cookie";
import {monthNames} from "../helpers/DateHelper";

const NewsItem: React.FC<NewsletterEntity> = ({ year, month, id }) => {
  const [{ profile }] = useCookies([ 'profile']);

  return (
    <Card className='shadow p-2'>
      <Card.Body>
        <div style={{ display: 'flex' }}>
          <div>
            <img src={'/assets/icons/News.png'} alt='News' />
          </div>
          <div className='mx-3' style={{ flex: 1 }}>
            <h5 className='h-24'>{monthNames[month - 1]}</h5>
            <h5 className='h-24'>{year}</h5>
          </div>
        </div>
        <Button variant="primary w-100" size="lg" className='px-4' href={`/${profile?.site.slug}/newsletter/${id}`}>Read Newsletter</Button>
      </Card.Body>
    </Card>
  );
};

export default NewsItem;
