import React, { useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import ViewPdf from '../components/ViewPdf';
import {useCookies} from "react-cookie";
import {apiURL} from "../common/config";

function Newsletter() {
  const [{ profile }] = useCookies([ 'profile']);
  const { id } = useParams();
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!profile) {
      return;
    }

    setUrl(`${apiURL}/newsletter/view/${id}`);
  }, [id, profile])

  return <ViewPdf doc={url} />
}

export default Newsletter;
