import { FaFacebookSquare, FaInstagramSquare, FaTiktok, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa';
import { UseTabs } from './UseTabs';
import { Col, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import {useCookies} from "react-cookie";
import {monthNames} from "../helpers/DateHelper";

export const UsePlatforms = ({ month, year } : { month: number, year: number } = {
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear()
}): [JSX.Element, string] => {
  const [{ profile }] = useCookies([ 'profile']);
  const platformList = useMemo(() => {
    if (!profile) {
      return [];
    }

    const list: { eventKey: string, title: JSX.Element }[] = [];
    const { facebook, instagram, twitter, youtube, tiktok } = profile.site;
    if (facebook) list.push({ eventKey: "Facebook", title: <span><FaFacebookSquare size={24} />{"Facebook"}</span> });
    if (instagram) list.push({ eventKey: "Instagram", title: <span><FaInstagramSquare size={24} />{"Instagram"}</span> });
    if (twitter) list.push({ eventKey: "Twitter", title: <span><FaTwitterSquare size={24} />{"Twitter"}</span> });
    if (youtube) list.push({ eventKey: "Youtube", title: <span><FaYoutubeSquare size={24} />{"Youtube"}</span> });
    if (tiktok) list.push({ eventKey: "TikTok", title: <span><FaTiktok size={24} />{"TikTok"}</span> });
    return list
  }, [profile])

  const [PlatformTabs, selectedPlatform] = UseTabs(platformList)

  const Platforms = useMemo(() => <Row className='mb-2'>
      <Col lg={4} md={12}>
        <h2 className={'header'}>Influencers In {profile?.site.title}</h2>
        <h4 className={'sub-header'}>as of {monthNames[month - 1]}, {year}</h4>
      </Col>
      <Col lg={8} md={12} className="md-left">
        {PlatformTabs}
      </Col>
    </Row>, [PlatformTabs, profile, month, year])
  
  return [Platforms, selectedPlatform]
}