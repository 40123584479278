// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  background-color: rgb(41, 46, 95);
}

.login-form {
  background-color: rgb(226, 169, 73);
  min-height: calc(100vh - 164px);
}

.logo,
.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form .card {
  width: 400px;
  max-width: 80vw;
}

@media only screen and (max-width: 768px) {

  .logo,
  .login-form {
    height: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Login.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;AACjC;;AAEA;;EAEE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE;;IAEE,YAAY;EACd;AACF","sourcesContent":[".logo {\n  background-color: rgb(41, 46, 95);\n}\n\n.login-form {\n  background-color: rgb(226, 169, 73);\n  min-height: calc(100vh - 164px);\n}\n\n.logo,\n.login-form {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.login-form .card {\n  width: 400px;\n  max-width: 80vw;\n}\n\n@media only screen and (max-width: 768px) {\n\n  .logo,\n  .login-form {\n    height: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
