import {Form} from "react-bootstrap";
import React from "react";
import {FieldError, UseFormRegister} from "react-hook-form";

export interface InputProps {
  name: string,
  label: string,
  error?: FieldError,
  register: UseFormRegister<any>,
  optional?: boolean,
}

const Input = ({ name, label, error, children } : InputProps & { children: React.ReactNode }) => {
  return <Form.Group className="mb-3" controlId={name}>
    <Form.Label>{label}</Form.Label>
    {children}
    {error && <Form.Text style={{ color: 'red' }}>
      {label} is required
    </Form.Text>
    }
  </Form.Group>
}


export default Input;