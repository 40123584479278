import React, { useContext, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import { AuthContext } from "../context/AuthContext";

import '../styles/Login.css';

const Login = () => {
  const { login } = useContext(AuthContext)
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const signIn = () => {
    login(userName, password);
  }

  return <Container fluid className='no-padding'>
    <Row>
      <Col className="logo p-5">
        <img src={'/assets/icons/logo.png'} alt='Trumi' />
      </Col>
      <Col className="login-form p-5">
        <Card className="shadow">
          <Card.Header>
            <Row className="m-2">
              <Col xs={8} className='text-orange'>MEMBER ONLY</Col>
              <Col xs={4} className='align-right'>Login</Col>
            </Row>
          </Card.Header>
          <Card.Body className="mx-2">
            <Row className="my-4">
              <Col>
                <input className="form-control border-secondary py-2" value={userName} onChange={(e) => setUserName(e.target.value)}  />
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <input className="form-control border-secondary py-2" type="password" value={password} onChange={(e) => setPassword(e.target.value)}  />  
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <Button variant="primary w-100" size="lg" className='px-4' onClick={signIn}>SIGN IN</Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
}

export default Login;