import React, { useCallback, useEffect, useMemo, useState } from "react"
import {Accordion, Col, Form, Row} from "react-bootstrap"
import { IDBData } from "../pages/DB"
import {monthNames, years} from "../helpers/DateHelper";

interface ICheckboxFilter {
  eventKey: string,
  header: string,
  values: string[],
  selected: (string | number)[],
  type: 'checkbox'
}

interface IRangeFilter {
  eventKey: string,
  header: string,
  values: string[],
  type: 'range'
}

interface IYearMonthFilter {
  eventKey: string,
  header: string,
  values: number[][],
  type: 'year-month'
}

type IFilter = ICheckboxFilter | IRangeFilter | IYearMonthFilter;

const unique = (list: any[]) => list.filter((item, i, ar) => item && ar.indexOf(item) === i);

export const UseFilters = ({ data, year, month } : { data: IDBData[], year: number, month: number }): [JSX.Element, (IFilter)[]] => {
  const [filters, setFilters] = useState<IFilter[]>([])

  const updateFilter = useCallback(({ eventKey, value, index, subIndex, selected } : { eventKey: string, value: string, index: number, subIndex?: number, selected?: boolean }) => {
    filters.forEach(f => {
      if (f.eventKey === eventKey) {
        if (f.type === 'range') {
          f.values[index] = value;
        } else if (f.type === 'checkbox') {
          const index = f.selected.indexOf(value);

          if (selected && index === -1) {
            f.selected.push(value);
          } else if (!selected && index > -1) {
            f.selected.splice(index, 1);
          }
        } else if (f.type === 'year-month') {
          if (subIndex !== undefined) {
            f.values[index][subIndex] = parseInt(value);
          }
        }
      }
    })

    setFilters([...filters]);
  }, [filters])

  useEffect(() => {
    const gender = unique(data.map(d => d.Gender));
    const category = unique(data.map(d => d.Category));
    // const region = unique(data.map(d => d.Region));

    setFilters([
      { eventKey: 'Year/Month', header: 'Year & Month', values: [[month, year], [month, year]], type: 'year-month' },
      { eventKey: 'Gender', header: 'Gender', values: gender, selected: [], type: 'checkbox' },
      { eventKey: 'Category', header: 'Category', values: category, selected: [], type: 'checkbox' },
      // { eventKey: 'Region', header: 'Region', values: region, selected: [] },
      { eventKey: 'Engagement', header: 'Engagement', values: [], type: 'range' },
      { eventKey: 'Audience', header: 'Audience', values: [], type: 'range' },
      { eventKey: 'Interactions Per Post', header: 'Interactions Per Post', values: [], type: 'range' },
      { eventKey: 'Number of Posts', header: 'No. of Posts', values: [], type: 'range' },
    ])
  }, [data, month, year])

  const renderCheckbox = (f: ICheckboxFilter, value: string, index: number) => {
    return <Form.Check
      key={index}
      style={{ textAlign: 'left' }}
      type={'checkbox'}
      label={value}
      id={`${f.eventKey}-${value}`}
      checked={f.selected.indexOf(value) > -1}
      onChange={(e) => updateFilter({
        index,
        value,
        eventKey: f.eventKey,
        selected: e.target.checked,
      })}
    />
  }

  const renderTextbox = (f: IRangeFilter, value: string, index: number) => {
    return <Form.Group as={Row} className="mb-3" key={index}>
      <Form.Label column sm="2">
        { index === 0 ? "Min" : "Max" }
      </Form.Label>
      <Col sm="10">
        <Form.Control
          className='mb-2'
          key={index}
          style={{ textAlign: 'left' }}
          type={'number'}
          id={`${f.eventKey}-${index}`}
          value={f.values[index] || ''}
          onChange={(e) => updateFilter({
            index,
            value: e.target.value,
            eventKey: f.eventKey,
          })}
        />
      </Col>
    </Form.Group>
  }

  const renderYearMonth = (f: IFilter, value: number[], index: number) => {
    return <Form.Group as={Row} className="mb-3" key={index}>
      <Form.Label column sm="3">
        { index === 0 ? "From" : "To" }
      </Form.Label>
      <Col sm="5">
        <Form.Select
          className='mb-2'
          key={index}
          style={{ textAlign: 'left' }}
          id={`${f.eventKey}-${value}`}
          value={value[0]}
          onChange={(e) => updateFilter({
            index,
            subIndex: 0,
            value: e.target.value,
            eventKey: f.eventKey,
          })}
        >
          {monthNames.map((text, value) => <option key={value} value={value + 1}>{text}</option>)}
        </Form.Select>
      </Col>
      <Col sm="4">
        <Form.Select
          className='mb-2'
          key={index}
          style={{ textAlign: 'left' }}
          id={`${f.eventKey}-${value}`}
          value={value[1]}
          onChange={(e) => updateFilter({
            index,
            subIndex: 1,
            value: e.target.value,
            eventKey: f.eventKey,
          })}
        >
          {years.map((value) => <option key={value} value={value}>{value}</option>)}
        </Form.Select>
      </Col>
    </Form.Group>
  }

  const FilterAccordion = useMemo(() => {
    return <Accordion defaultActiveKey={'gender'}>
      {filters.map((f, index) =>
        <Accordion.Item key={index} eventKey={f.eventKey}>
          <Accordion.Header>{f.header}</Accordion.Header>
          <Accordion.Body>
            {f.type === 'range' && [f.values[0] || '', f.values[1] || ''].map((val, index1) => renderTextbox(f, val, index1))}
            {f.type === 'year-month' && [f.values[0] || [], f.values[1] || []].map((val, index1) => renderYearMonth(f, val, index1))}
            {f.type === 'checkbox' && f.values.map((val, index1) => renderCheckbox(f, val, index1))}
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  }, [filters, updateFilter])

  return [FilterAccordion, filters]
}