import axios from 'axios';
import {apiURL} from "../common/config";

axios.defaults.baseURL = apiURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
    // console.log(request);
    // Edit request config
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    // console.log(response);
    // Edit response config
    return response;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

export class AxiosService {
    static async get<T>(url: string) {
        try {
            const response = await axios.get(url);
            return response.data as T
        } catch {
            return null;
        }
    }

    static async post<T>(url: string, data: Record<string, any> = {}, contentType?: string) {
        try {
            const response = await axios.post(url, data, {
                headers: { "Content-Type": contentType || 'application/json' },
            });
            return response.data as T
        } catch {
            return null;
        }
    }

    static async delete(url: string) {
        try {
            const response = await axios.delete(url);
            return response.data
        } catch {
            return null;
        }
    }
}