import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import {useForm, SubmitHandler } from "react-hook-form";
import InputText from '../components/InputText';
import InputTextArea from "../components/InputTextArea";
import InputSelect from "../components/InputSelect";
import {monthNames, years} from "../helpers/DateHelper";
import {CategoryEntity} from "../entities/category.entity";
import {CategoryService} from "../services/category.service";
import {useCookies} from "react-cookie";
import Input from "../components/Input";
import {StoryService} from "../services/story.service";
import {useNavigate} from "react-router-dom";
import {StoryEntity} from "../entities/story.entity";

const AdminStoryAdd = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors},
    setValue,
    getValues
  } = useForm<StoryEntity>({ defaultValues: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
  }})

  const [categories, setCategories] = useState<CategoryEntity[]>([]);
  const [images, setImages] = useState<{
    file: File,
    linkOut: string,
    isMain: boolean
  }[]>([]);

  const imageForm = useForm<{
    files: FileList,
    linkOut: string
  }>();

  const onSubmit: SubmitHandler<StoryEntity> = async (story) => {
    if (images.length === 0) {
      return;
    }

    await StoryService.create(story, images);
    navigate(`/${profile?.site.slug}/admin`);
  }

  const fetchData = async () => {
    const categories = await CategoryService.list();
    setCategories(categories);
  }

  const selectMainImage = useCallback((name: string) => {
      images.forEach(i => i.isMain = i.file.name === name);
      setImages([...images]);
  }, [images])

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  useEffect(() => {
    const { categoryId } = getValues()
    if (!categoryId && categories.length > 0) {
      setValue('categoryId', categories[0].id);
    }
  }, [categories, getValues, setValue]);

  useEffect(() => {
    if (images.length === 0) {
      return;
    }

    const mainImage = images.find(i => i.isMain);
    if (!mainImage) {
      images[0].isMain = true;
      setImages([...images]);
    }
  }, [images]);

  return <Container fluid>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className='shadow'>
        <Card.Header className='p-3'>
          <h2 className='h-24'>Add {profile.site.storyTitle}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <InputText name="title" register={register} error={errors.title} label="Title" />
            </Col>
          </Row>

          <Row>
            <Col>
              <InputSelect name="categoryId" register={register} error={errors.categoryId} label="Category" items={
                categories.map((c) => ({ text: c.title, value: c.id }))
              } />
            </Col>
            <Col>
              <Row>
                <Col>
                  <InputSelect name="month" register={register} error={errors.month} label="Month" items={
                    monthNames.map((text, i) => ({ text, value: i + 1 }))
                  } />
                </Col>
                <Col>
                  <InputSelect name="year" register={register} error={errors.year} label="Year" items={
                    years.map((value) => ({ text: value.toString(), value }))
                  } />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputTextArea name="whatHappened" register={register} error={errors.whatHappened} label="Whats's Happening" rows={3} />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputTextArea name="whyItMatters" register={register} error={errors.whyItMatters} label="Why it Matters" rows={3} optional={true} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Select Main Image (appears first in carousel)</Form.Label>
                <Form.Select onChange={(e) => selectMainImage(e.target.value)}>
                  {images.map(({ file, isMain }) => <option key={file.name} value={file.name} selected={isMain}>{file.name}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Table>
              <thead>
                <tr style={{ textAlign: 'left' }}>
                  <th>Image Filename</th>
                  <th>Link Out</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {images.map((i, index) => {
                  return <tr style={{ textAlign: 'left' }} key={index}>
                    <td>{i.file.name}</td>
                    <td>{i.linkOut}</td>
                    <td style={{ textAlign: 'right' }}>
                      <Button variant="danger" onClick={() => {
                        images.splice(index, 1);
                        setImages([...images]);
                      }}>Delete</Button>
                    </td>
                  </tr>
                })}
                <tr style={{ textAlign: 'left' }}>
                  <td>
                    <Input name="files" label="Image File" register={imageForm.register} error={imageForm.formState.errors.files}>
                      <Form.Control type="file" accept=".jpg, .png, .jpeg, .gif" {...imageForm.register("files", { required: true })} />
                    </Input>
                  </td>
                  <td>
                    <Input name="linkout" label="Link Out Optional" register={imageForm.register} error={imageForm.formState.errors.linkOut}>
                      <Form.Control type="input" {...imageForm.register("linkOut")} />
                    </Input>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Button variant="warning" type='button' onClick={() => {
                      const { files, linkOut }  = imageForm.getValues();
                      if (!(files && files[0])) {
                        return;
                      }

                      setImages([...images, {
                        file: files[0],
                        linkOut: linkOut || '',
                        isMain: images.length === 0
                      }]);

                      imageForm.resetField('files');
                      imageForm.resetField('linkOut');
                    }}>Add Image</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Card.Body>
        <Card.Footer className='p-3'>
          <ButtonGroup className='float-end'>
            <Button variant="secondary" href={`/${profile?.site.slug}/admin`}>Cancel</Button>
            <Button variant="primary" type="submit">
              Publish
            </Button>
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Form>
  </Container>
}

export default AdminStoryAdd;