import { Button, Card, Col, Container, Row } from "react-bootstrap"
import Papa from 'papaparse';
import { UseTabs } from "../hooks/UseTabs";
import { LineChart } from "../components/LineChart";
import React, { useEffect, useMemo, useState } from "react";
import { formatDate, formatDateToYYMMDD, getDurationStartDate, getDurationString } from "../helpers/DateHelper";
import { formatNumber } from "../helpers/NumberHelper";

import '../styles/Music.css';
import {useCookies} from "react-cookie";
import {sortByNumberProp} from "../helpers/ArrayHelper";
import {readCSV} from "../helpers/CSVHelper";

interface IMusicData {
  Rank: number,
  "Previous Rank": number,
  "Track Name": string,
  "Artist Names": string,
  "Weeks on Chart": number,
  "Views": number,
  "Weekly Growth": number,
  "YouTube URL": string,
  "Week Date": Date
}

interface ITableData extends IMusicData {
  PeakPlays: number,
  TotalPlays: number
}

const generateChartData = (labels: string[], data: number[][]) => {
  const datasets = [{
    data: data[0],
    borderColor: 'rgb(41, 46, 95)',
    backgroundColor: 'rgba(41, 46, 95, 0.5)',
  }];

  if (data.length > 1) {
    datasets.push({
      data: data[1],
      borderColor: 'rgb(226, 169, 73)',
      backgroundColor: 'rgba(226, 169, 73, 0.5)',
    })
  }
  
  return {
    labels,
    datasets,
  };
}

const Music = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [weekDate, setWeekDate] = useState(new Date());
  const [selected, setSelected] = useState<string[]>([]);
  const [csvData, setCsvData] = useState<ITableData[]>([]);
  const [chartData, setChartData] = useState(generateChartData([], []));
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [inThisPeriod, setInThisPeriod] = useState({
    trackPlayed: 0,
    newArtists: 0,
    newTracks: 0
  });

  const [SortTabs, sortBy] = UseTabs([
    {eventKey:"Views", title:"This Week"},
    {eventKey:"PeakPlays", title:"Peak Plays"},
    {eventKey:"TotalPlays", title:"Total Plays"}
  ])

  const selectedDuration = "-365";

  useEffect(() => {
    if (!profile) {
      return;
    }

    const url = `/assets/${profile?.site.slug}/data/music_charts_youtube.csv`;
    readCSV(url, (data) => {
      setCsvData(data.filter(d => !!d["Week Date"]).map(d => {
        const [year, month, day] = (d["Week Date"] as unknown as string).split('-').map(Number);
        return {
          ...d,
          "Week Date": new Date(2000 + year, month - 1, day)
        }
      }));
    })
  }, [profile])

  useEffect(() => {
    const weekDates = csvData
      .map(d => d["Week Date"])
      .sort((a, b) => a < b ? 1 : -1);
    
    setWeekDate(weekDates[0] || new Date());
  }, [csvData])

  useEffect(() => {
    const group = csvData
      .reduce((group: {[key: string]: IMusicData[]}, item) => {
        if (!group[item["Track Name"]]) {
        group[item["Track Name"]] = [];
        }
        group[item["Track Name"]].push(item);
        return group;
      }, {})

    const weekDateString = weekDate.toISOString();
    const groupData: ITableData[] = Object.values(group)
      .filter(d => d.some(s => s["Week Date"].toISOString() === weekDateString))
      .map(d => ({
        ...(d.find(d => d["Week Date"].toISOString() === weekDateString) || d[0]),
        PeakPlays: d.reduce((max, obj) => {
            return obj["Views"] > max ? obj["Views"] : max;
          }, d[0]["Views"]),
        TotalPlays: d.reduce((total, obj) => {
          return total + obj["Views"];
        }, 0)
      }))

    setTableData(groupData);
  }, [csvData, weekDate])

  useEffect(() => {
    let startDate = getDurationStartDate(weekDate, selectedDuration);
    const data = csvData
      .filter(d => (selected.length === 0 || selected.indexOf(d["Track Name"]) > -1) && d["Week Date"] <= weekDate && d["Week Date"] >= startDate)
      .sort((a, b) => (a["Week Date"] > b["Week Date"]) ? 1 : -1);
    
    const group: number[][] = [];
    let labels: string[] =  data
      // .map(item => selectedDuration === "-365" 
      //   ? item["Week Date"].toLocaleString('en-US', { month: 'short' }) 
      //   : formatDateToYYMMDD(item["Week Date"]))
      .map(item => formatDateToYYMMDD(item["Week Date"]))
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

    const trackPlayed = data.reduce((t, i) => t + i.Views, 0);
    const newArtists = data.map(d => d["Artist Names"]).filter((item, i, ar) => ar.indexOf(item) === i).length;
    const newTracks = data.map(d => d["Track Name"]).filter((item, i, ar) => ar.indexOf(item) === i).length;

    const results: any[] = [];

    (selected.length > 0 ? selected : ["total"]).forEach((s, i) => {
      const result = data
        .filter(d => selected.length === 0 || d["Track Name"] === s)
        .reduce((group: Record<string, number>, item) => {
          // const date = selectedDuration === "-365" 
          //   ? item["Week Date"].toLocaleString('en-US', { month: 'short' }) 
          //   : formatDateToYYMMDD(item["Week Date"]);
          const date = formatDateToYYMMDD(item["Week Date"]);

          if (!group[date]) {
            group[date] = 0;
          }
          
          group[date] += item.Views;
          return group;
        }, {})
      
      results.push(result);
    })

    results.forEach(r => {
      let values: any[] = [];
      labels.forEach(l => {
        values.push(r[l] || 0);
      })
      group.push(values);
    })

    setChartData(generateChartData(labels, group));
    setInThisPeriod({ trackPlayed, newArtists, newTracks })
  }, [csvData, weekDate, selectedDuration, selected])

  const sortedData = useMemo(() => {
    const prop = sortBy as unknown as keyof ITableData
    return sortByNumberProp(tableData, prop);
  }, [sortBy, tableData])

  const toggleSelected = (value: string) => {
    const index = selected.indexOf(value);
          
    if (index === -1) {
      if (selected.length === 2) {
        return;
      }
      
      selected.push(value);
    } else if(index > -1) {
      selected.splice(index, 1);
    }

    setSelected([...selected])
  }

  return <Container fluid>
    <Row className='mb-2'>
      <Col lg={4} md={12}>
        <h2 className={'header'}>Music In {profile?.site.title}</h2>
        <h4 className={'sub-header'}>as of {formatDate(weekDate)}</h4>
      </Col>
      {/* <Col lg={8} md={12} className="md-left">
       {SourcesTabs}
      </Col> */}
    </Row>
    <Row>
      <Col xl={9} md={12}>
        <Card className="mb-2 period p-4 chart-card shadow">
          <Row>
            <Col md={6}>
              <h4 className={'h-20'}>Total Plays</h4>
            </Col>
            {/* <Col md={6}>
              {DurationTabs}
            </Col> */}
          </Row>
          <Row>
            <Col>
              <LineChart data={chartData}  />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xl={3} md={12}>
        <Card className="mb-2 period p-4 chart-card shadow">
          <h4 className={'h-20'}>In This Period</h4>
          <h5 className='sub-header'>{getDurationString(weekDate, selectedDuration)}</h5>
          <Row className="mt-4">
            <Col xl={3} md={2}>
              <img src={"/assets/icons/Tracks Played.png"} alt='Tracks Played' />
            </Col>
            <Col xl={9} md={10}>
              <h3 className={'header'}>{formatNumber(inThisPeriod.trackPlayed)}</h3>
              <h5 className='sub-header'>Track Played</h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={3} md={2}>
              <img src={"/assets/icons/New Artists.png"} alt='New Artists' />
            </Col>
            <Col xl={9} md={10}>
              <h3 className={'header'}>{formatNumber(inThisPeriod.newArtists)}</h3>
              <h5 className='sub-header'>New Artists</h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={3} md={2}>
              <img src={"/assets/icons/New Tracks.png"} alt='New Tracks' />
            </Col>
            <Col xl={9} md={10}>
              <h3 className={'header'}>{formatNumber(inThisPeriod.newTracks)}</h3>
              <h5 className='sub-header'>New Tracks</h5>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col>
        <Card className="mt-2 p-3 shadow">
          <Row className="p-2">
            <Col xl={6} md={5}>
              <h4 className={'h-20'}>Top Tracks</h4>
            </Col>
            <Col xl={6} md={7}>
              {SortTabs}
            </Col>
          </Row>
          {sortedData.map((d, index) => {
            if (!showMore && index >= 10) {
              return null;
            }
            return <Card key={index} className={`mb-4 selected${selected.indexOf(d["Track Name"]) + 1}`} onClick={() => toggleSelected(d["Track Name"])}>
              <Row className="m-2 p-1 db-box">
              <Col md={1}>
                <h3 className="index">{index + 1}</h3>
              </Col>
              <Col xl={7} md={5}>
                <h5 className='artist'>{d["Artist Names"]}</h5>
                <h3 className='track'>{d["Track Name"]}</h3>
              </Col>
              <Col md={1}>
                {formatNumber(d.Views)}
              </Col>
              <Col md={1}>
                {formatNumber(d.PeakPlays)}
              </Col>
              <Col md={1}>
                {formatNumber(d.TotalPlays)}
              </Col>
              <Col xl={1} md={2}>
                <Button variant="outline-secondary" size="sm" className='px-4' href={d["YouTube URL"]} target="_blank">Open</Button>
              </Col>
              </Row>
            </Card>
          })}
          {!showMore ? <img className="show-more" src={"/assets/icons/View More Button.png"} alt='View More' onClick={() => setShowMore(true)} /> : null}
        </Card>
      </Col>
    </Row>
  </Container>
}

export default Music;