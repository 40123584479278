import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthProvider from './context/AuthContext';
import {CookiesProvider, useCookies} from 'react-cookie';

import './App.css';

import Header from './components/Header';
import Stories from './pages/Stories';
import Chart from './pages/Chart';
import DB from './pages/DB';
import Music from './pages/Music';
import Brands from './pages/Brands';
import Login from './pages/Login';
import Brand from './pages/Brand';
import Newsletters from './pages/Newsletters';
import Newsletter from './pages/Newsletter';
import AdminStories from "./pages/AdminStories";
import AdminBrands from "./pages/AdminBrands";
import AdminNewsletters from "./pages/AdminNewsletters";
import AdminStoryAdd from "./pages/AdminStoryAdd";
import AdminBrandAdd from "./pages/AdminBrandAdd";
import AdminNewsletterAdd from "./pages/AdminNewsletterAdd";
import {useViewportWidth} from "./hooks/UseViewportWidth";

function App() {
  const [{profile}] = useCookies([ 'access_token', 'profile']);
  const site  = profile ? `/${profile.site.slug}` : '/';

  const { isSmallScreen } = useViewportWidth();

  return <div className="App">
      { profile ? <Header /> : null}
      <Routes>
        {
          profile ?
            <>
              <Route path={`${site}`} element={<Stories />} />
              {!isSmallScreen ? <Route path={`${site}/music`} element={<Music />} /> : null}
              <Route path={`${site}/brands`} element={<Brands />} />
              <Route path={`${site}/brand/:id`} element={<Brand />} />
              {!isSmallScreen ? <Route path={`${site}/chart`} element={<Chart />} /> : null}
              {!isSmallScreen ? <Route path={`${site}/db`} element={<DB />} /> : null}
              <Route path={`${site}/newsletters`} element={<Newsletters />} />
              <Route path={`${site}/newsletter/:id`} element={<Newsletter />} />
                {
                  profile.isAdmin && !isSmallScreen
                    ? <>
                        <Route path={`${site}/admin`} element={<AdminStories />} />
                        <Route path={`${site}/admin/story/add`} element={<AdminStoryAdd />} />
                        <Route path={`${site}/admin/brands`} element={<AdminBrands />} />
                        <Route path={`${site}/admin/brand/add`} element={<AdminBrandAdd />} />
                        <Route path={`${site}/admin/newsletters`} element={<AdminNewsletters />} />
                        <Route path={`${site}/admin/newsletter/add`} element={<AdminNewsletterAdd />} />
                      </>
                    : <></>
                }
              <Route path="*" element={<Navigate to={site} />} />
            </> :
            <>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
        }
        <Route path="safaricom" element={
          <div style={{ position: "absolute", height:"100vh", width:"100vw", background:"white" }}>
            <iframe src="https://trumi.odipodev.com/safaricom/" style={{ height:"100vh", width:"100vw" }} />
          </div>
        } />
        <Route path="/" element={
          <div style={{ position: "absolute", height:"100vh", width:"100vw", background:"white" }}>
            <iframe src="https://trumi.odipodev.com/" style={{ height:"100vh", width:"100vw" }} />
          </div>
        } />
      </Routes>
    </div>
  }

const Root = () => {
  return (
    <CookiesProvider>
       <Router>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </CookiesProvider>
  )
}

export default Root;
