// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2738%27 height=%2717%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M2.31481 1.5H28.1296%27 stroke=%27%23E1A949%27 stroke-width=%273%27 stroke-linecap=%27round%27/%3E%3Cpath d=%27M2.31481 8.5H35.6852%27 stroke=%27%23E1A949%27 stroke-width=%273%27 stroke-linecap=%27round%27/%3E%3Cpath d=%27M2.31481 15.5H28.1296%27 stroke=%27%23E1A949%27 stroke-width=%273%27 stroke-linecap=%27round%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath stroke=%27rgba%28255,255,255, 1%29%27 stroke-width=%272%27 d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar button,
.offcanvas-header button {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff;
  padding: 0;
}

.navbar .navbar-toggler-icon {
  width: 38px;
  /* Set the width and height to match the SVG dimensions */
  height: 17px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.navbar .navbar-brand {
  margin: 0;
}

.offcanvas {
  background-color: rgb(41, 46, 95);
  color: #fff;
}

.offcanvas-backdrop {
  opacity: 0 !important;
}

.offcanvas .btn-close {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.nav-link span {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;EACrB,2BAA2B;EAC3B,uBAAuB;EACvB,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,yDAAyD;EACzD,YAAY;EACZ,yDAAqa;AACva;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yDAAkY;AACpY;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".navbar button,\n.offcanvas-header button {\n  outline: 0 !important;\n  box-shadow: none !important;\n  border: none !important;\n  color: #fff;\n  padding: 0;\n}\n\n.navbar .navbar-toggler-icon {\n  width: 38px;\n  /* Set the width and height to match the SVG dimensions */\n  height: 17px;\n  background-image: url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='38' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.31481 1.5H28.1296' stroke='%23E1A949' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M2.31481 8.5H35.6852' stroke='%23E1A949' stroke-width='3' stroke-linecap='round'/%3E%3Cpath d='M2.31481 15.5H28.1296' stroke='%23E1A949' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E\");\n}\n\n.navbar .navbar-brand {\n  margin: 0;\n}\n\n.offcanvas {\n  background-color: rgb(41, 46, 95);\n  color: #fff;\n}\n\n.offcanvas-backdrop {\n  opacity: 0 !important;\n}\n\n.offcanvas .btn-close {\n  background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-width='2' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e\");\n}\n\n.nav-link span {\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 24px;\n  letter-spacing: 0px;\n  text-align: center;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
