import React, {ReactNode, useCallback, useEffect} from "react";
import {useCookies} from 'react-cookie';
import {AuthService} from "../services/auth.service";
import axios from 'axios';

interface IAuthContext {
  login: (userName: string, password: string) => Promise<void>,
  logout: () => Promise<void>
}

// Create a context for authentication state
export const AuthContext = React.createContext<IAuthContext>({ 
  login: async () => {},
  logout: async () => {}
});

// AuthProvider component provides authentication data
const AuthProvider = ({ children } : { children: ReactNode }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'profile']);
  if (cookies.access_token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${cookies.access_token}`;
  }

  const login = async (username: string, password: string) => {
    const response = await AuthService.login({ username, password })

    if (response?.access_token) {
      setCookie('access_token', response?.access_token, {
        path: "/",
        maxAge: 365 * 24 * 3600
      });
    }
  };

  const logout = async () => {
    await AuthService.logout();
    removeCookie('access_token', { path: "/" });
    removeCookie('profile', { path: "/" });
  };

  const fetchProfile = async () => {
    const profile = await AuthService.profile();
    setCookie('profile', profile, {
      path: "/",
      maxAge: 365 * 24 * 3600
    });
  }

  useEffect(() => {
    if (!cookies.access_token) {
      return;
    }

    fetchProfile();
  }, [cookies.access_token]);

  return (
    <AuthContext.Provider value={{ login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;