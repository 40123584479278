// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.artist {
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0px;
  color: #7F808699;
}

.track {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #3A3B45;
}

.index {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #292E5F;
}

.chart-card {
  height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Music.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".artist {\n  font-size: 9px;\n  font-weight: 500;\n  line-height: 11px;\n  letter-spacing: 0px;\n  color: #7F808699;\n}\n\n.track {\n  font-size: 15px;\n  font-weight: 700;\n  line-height: 18px;\n  letter-spacing: 0px;\n  color: #3A3B45;\n}\n\n.index {\n  font-size: 15px;\n  font-weight: 700;\n  line-height: 18px;\n  letter-spacing: 0px;\n  text-align: center;\n  color: #292E5F;\n}\n\n.chart-card {\n  height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
