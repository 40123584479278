import React from 'react';
import { Card, Button, Carousel } from 'react-bootstrap';
import {BrandEntity} from "../entities/brand.entity";
import {useCookies} from "react-cookie";
import {apiURL} from "../common/config";

const BrandItem: React.FC<BrandEntity> = ({ title, description, thumbnail, id }) => {
  const [{ profile }] = useCookies([ 'profile']);
  
  return (
    <Card className='shadow'>
      <Carousel 
        interval={null} 
        indicators={false} 
        controls={false}
        nextIcon ={<img src={"/assets/icons/right_arrow.png"} alt='Next' />}
        prevIcon={<img src={"/assets/icons/left_arrow.png"} alt='Prev' />}
      >
        <Carousel.Item>
          <img className="d-block w-100" src={`${apiURL}/brand/thumbnail/${id}`} alt={thumbnail} />
        </Carousel.Item>
      </Carousel>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button variant="primary w-100" size="lg" className='px-4' href={`/${profile?.site.slug}/brand/${id}`}>View PDF</Button>
      </Card.Body>
    </Card>
  );
};

export default BrandItem;
