export const sortByYearAndMonth = (data: { year: number, month: number }[]) => {
  const list = [...data];
  list.sort((a, b) => {
    if (a.year > b.year) {
      return -1; // Reverse the comparison result for years.
    } else if (a.year < b.year) {
      return 1; // Reverse the comparison result for years.
    } else {
      // If the years are the same, compare the months using the monthMap.
      if (a.month > b.month) {
        return -1; // Reverse the comparison result for months.
      } else if (a.month < b.month) {
        return 1; // Reverse the comparison result for months.
      } else {
        return 0; // The years and months are the same.
      }
    }
  });

  return list as any[];
}

export const sortByNumberProp = (data: any[], prop: string) => {
  const list = [...data];
  list.sort((a, b) => {
    const v1 = parseFloat(a[prop]) || 0;
    const v2 = parseFloat(b[prop]) || 0;
    return v1 < v2 ? 1 : -1
  })

  return list as any[];
}