import React from 'react';
import {Button, ButtonGroup, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useForm, SubmitHandler } from "react-hook-form";
import InputText from '../components/InputText';
import InputTextArea from "../components/InputTextArea";
import {useCookies} from "react-cookie";
import Input from "../components/Input";
import {useNavigate} from "react-router-dom";
import {BrandService} from "../services/brand.service";
import {BrandWithFile} from "../entities/brand.entity";

const AdminBrandAdd = () => {
    const [{ profile }] = useCookies([ 'profile']);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors},
    } = useForm<BrandWithFile>()

    const onSubmit: SubmitHandler<BrandWithFile> = async (brand) => {
       await BrandService.create(brand);
       navigate(`/${profile?.site.slug}/admin/brands`);
    }

    return <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card className='shadow'>
                <Card.Header className='p-3'>
                    <h2 className='h-24'>Add Brand In Culture</h2>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <InputText name="title" register={register} error={errors.title} label="Title" />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <InputTextArea name="description" register={register} error={errors.description} label="Description" rows={3} />
                        </Col>
                    </Row>

                    <Row>
                       <Col>
                           <Input name="files" label="Thumbnail" register={register} error={errors.thumbnail}>
                               <Form.Control type="file" accept=".jpg, .png, .jpeg, .gif" {...register("thumbnail", { required: true })} />
                           </Input>
                       </Col>

                        <Col>
                            <Input name="files" label="Pdf file" register={register} error={errors.pdf}>
                                <Form.Control type="file" accept=".pdf" {...register("pdf", { required: true })} />
                            </Input>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className='p-3'>
                    <ButtonGroup className='float-end'>
                        <Button variant="secondary" href={`/${profile?.site.slug}/admin`}>Cancel</Button>
                        <Button variant="primary" type="submit">
                            Publish
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </Form>
    </Container>
}

export default AdminBrandAdd;