import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import Papa from 'papaparse';
import { UseTabs } from '../hooks/UseTabs';
import { UsePlatforms } from '../hooks/UsePlatforms';
import { UseFilters } from '../hooks/UseFilters';
import { formatNumber } from '../helpers/NumberHelper';
import {sortByNumberProp, sortByYearAndMonth} from '../helpers/ArrayHelper';
import {useCookies} from "react-cookie";
import {monthMap} from "../helpers/DateHelper";
import {readCSV} from "../helpers/CSVHelper";

export interface IDBData {
  Influencer: string,
  Audience: number,
  "Number of Posts": number,
  Engagement: number,
  "Interactions Per Post": number,
  Category: string,
  Gender: string,
  Time: string,
  Month: string,
  Region: string,
  Year: number,
  Link: string
}

const DB = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [data, setData] = useState<IDBData[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [PlatformTabs, selectedPlatform] = UsePlatforms({ month, year });

  const [FilterAccordion, filters] = UseFilters({data, year, month});

  const [SortTabs, sortBy] = UseTabs([
    {eventKey:"Engagement", title:"Engagement"},
    {eventKey:"Audience", title:"Audience"},
    {eventKey:"Interactions Per Post", title:"Interactions Per Post"},
    {eventKey:"Number of Posts", title:"No. of Posts"}
  ])

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (!selectedPlatform) {
      return
    }
    
    const url = `/assets/${profile?.site.slug}/data/influencers - ${selectedPlatform}.csv`;
    readCSV(url, (data) => {
      const mapData = data.map((d) => ({
        ...d,
        year: d.Year,
        month: monthMap[d.Time] || monthMap[d.Month]
      }))
      const sortData = sortByYearAndMonth(mapData);

      if (sortData.length > 0) {
        setMonth(sortData[0].month);
        setYear(sortData[0].year);
      }
      setData(sortData)
    })
  }, [profile, selectedPlatform])

  const tableData = useMemo(() => {
    const filterData = data.filter(d => {
      if (searchText) {
        if (d.Influencer.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) === -1) {
          return false;
        }
      }
      
      for (let f of filters) {
        const key = f.eventKey as unknown as keyof IDBData;
        const value = d[key];

        if (f.type === 'range') {
          const intValue =  parseInt(value + "");
          if (f.values.length > 0 && isNaN(intValue)) {
            return false;
          }

          const min = parseInt(f.values[0]);
          if (!isNaN(min) && intValue < min) {
            return false;
          }

          const max = parseInt(f.values[1]);
          if (!isNaN(max) && intValue > max) {
            return false;
          }
        } else if (f.type === 'checkbox') {
          if (f.selected.length > 0) {
            if (f.selected.indexOf(value) === -1) {
              return false
            }
          }
        } else if (f.type === 'year-month') {
          const itemYear = d.Year;
          const itemMonth = monthMap[d.Time] || monthMap[d.Month];

          const [ [startMonth, startYear], [endMonth, endYear] ] = f.values;

          const isAfterStart = itemYear > startYear || (itemYear === startYear && itemMonth >= startMonth);
          const isBeforeEnd = itemYear < endYear || (itemYear === endYear && itemMonth <= endMonth);

          if (!(isAfterStart && isBeforeEnd)) {
            return false;
          }
        }
      }

      return true
    })

    const prop = sortBy as unknown as keyof IDBData
    return sortByNumberProp(filterData, prop);
  }, [searchText, sortBy, filters, data])

  useEffect(() => {
    setShowMore(tableData.length < 10);
  }, [tableData])

  return <Container fluid>
    {PlatformTabs}
    <Row>
      <Col lg={3} md={12}>
        <Card className="mt-3 bg-white shadow">
        {FilterAccordion}
        </Card>
      </Col>
      <Col lg={9} md={12}>
        <Card className="mt-3 p-3 bg-white shadow">
          <Row className="p-2">
            <Col xl={4}>
              <InputGroup>
                <input className="form-control border-secondary py-2" type="search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <Button variant="outline-secondary">
                  <FaSearch size={26} />
                </Button>
              </InputGroup>
            </Col>
            <Col xl={8}>
            {SortTabs}
            </Col>
          </Row>
          {tableData.map((d, index) => {
            if (!showMore && index >= 10) {
              return null;
            }
            return <Card className={`mb-4`} key={index}>
              <Row key={index} className="m-2 p-1 db-box">
                <Col md={6}>
                  <h5 className='sub-header'>{d.Category}</h5>
                  <h3>{d.Influencer}</h3>
                </Col>
                <Col md={1}>
                  {formatNumber(d.Engagement)}
                </Col>
                <Col md={1}>
                  {formatNumber(d.Audience)}
                </Col>
                <Col md={1}>
                  {formatNumber(d["Interactions Per Post"], 2)}
                </Col>
                <Col md={1}>
                  {formatNumber(d["Number of Posts"])}  
                </Col>
                <Col md={2}>
                  <Button variant="outline-secondary" size="sm" className='px-4' href={d.Link} target='_blank'>Open</Button>
                </Col>
              </Row>
            </Card>
          })}
          {!showMore ? <img className="show-more" src={"/assets/icons/View More Button.png"} alt='View More' onClick={() => setShowMore(true)} /> : null}
        </Card>
      </Col>
    </Row>
      
    </Container>
};

export default DB;
