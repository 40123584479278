import {AxiosService} from "./axios.service";
import {NewsletterEntity, NewsletterWithFile} from "../entities/newsletter.entity";

export class NewsletterService {
    static async list() {
        const response = await AxiosService.get<NewsletterEntity[]>('/newsletter/list');
        return response || [];
    }

    static async create(newsletter: NewsletterWithFile) {
        const formData = new FormData();

        formData.append("pdf", newsletter.pdf[0],  newsletter.pdf[0].name)

        formData.append("newsletter", JSON.stringify(newsletter));

        return await AxiosService.post('/newsletter', formData, "multipart/form-data");
    }

    static async delete(id: number) {
        await AxiosService.delete(`/newsletter/${id}`);
    }
}