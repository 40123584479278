import {AxiosService} from "./axios.service";
import {StoryEntity, StoryWithCategory} from "../entities/story.entity";

export class StoryService {
    static async list() {
        const response = await AxiosService.get<StoryWithCategory[]>('/story/list');
        return response || [];
    }

    static async create(story: StoryEntity, images: {
        file: File,
        linkOut: string,
        isMain: boolean
    }[]) {
        const formData = new FormData();

        images.forEach(i => formData.append("images", i.file, i.file.name));
        const data: StoryEntity = {
            ...story,
            images: images.map(({ file, ...rest }) =>  ({ src: file.name, ...rest })) as any[],
        }

        formData.append("story", JSON.stringify(data));

        return await AxiosService.post('/story', formData, "multipart/form-data");
    }

    static async delete(id: number) {
        await AxiosService.delete(`/story/${id}`);
    }
}