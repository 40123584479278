export function formatNumber(number: number, fraction: number = 0) {
  const value = parseInt((number || "").toString());
  if (isNaN(value)) {
    return "-";
  }
  
  if (value < 1000) {
    return value.toFixed(fraction);
  } else if (value < 1000000) {
    return (value / 1000).toFixed(1) + 'K';
  } else if (value < 1000000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else {
    return (value / 1000000000).toFixed(1) + 'B';
  }
}