import React, { useEffect, useMemo, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"

interface ITabsHeader {
  eventKey: string,
  title: JSX.Element | string,
  disabled?: boolean
}

export const UseTabs = (headers: ITabsHeader[]): [JSX.Element, string] => {
  const [selected, setSelected] = useState<string>(headers.length > 0 ? headers[0].eventKey : '')

  useEffect(() => {
    if (!selected) {
      setSelected(headers.length > 0 ? headers[0].eventKey : '');
    }
  }, [headers, selected])

  useEffect(() => {
    const el = document.querySelector('.nav-link.active');
    if (el) { el.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" }); }
  }, [selected])

  const tabs = useMemo(() => <Tabs
      activeKey={selected}
      onSelect={(k) => k && setSelected(k)}
      className="mb-3"
    >
      {headers.map((h, index) => <Tab key={index} {...h} onSelect={(e) => console.log(e.target)}></Tab>)}
    </Tabs>, [selected, headers])
  
  return [tabs, selected]
}