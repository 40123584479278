export const monthNames = [
  "January", "February",
  "March", "April", "May",
  "June", "July", "August",
  "September", "October",
  "November", "December"
];

export const monthMap: Record<string, number> = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12
};

export const years = Array.from({ length: new Date().getFullYear() - 2020 }, (_, i) => new Date().getFullYear() - i);

function getDaySuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function formatDate(date: Date) {
  const options: any = { year: 'numeric', month: 'long' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const dayNumber = date.getDate();
  const daySuffix = getDaySuffix(dayNumber);

  return `${dayNumber}${daySuffix} ${formattedDate}`;
}

export function formatDateToYYMMDD(date: Date) {
  const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (add 1 as months are zero-indexed)
  const day = String(date.getDate()).padStart(2, '0'); // Get the day of the month

  return `${year}-${month}-${day}`;
}

export function formatDateToYYYMM(date: Date) {
  const options: any = { year: 'numeric', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
}

export function formatDateToDDMM(date: Date) {
  const options: any = { month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const dayNumber = date.getDate();
  const daySuffix = getDaySuffix(dayNumber);

  return `${dayNumber}${daySuffix} ${formattedDate}`;
}

export function getDurationStartDate(weekDate: Date, selectedDuration: string) {
  let date = new Date(weekDate.getTime());
  date.setDate(date.getDate() + parseInt(selectedDuration) + 1);

  return date;
}

export function getDurationString(weekDate: Date, selectedDuration: string) {
  const startDate = getDurationStartDate(weekDate, selectedDuration);

  if (selectedDuration === "-365" ) {
    return `${formatDateToYYYMM(startDate)} - ${formatDateToYYYMM(weekDate)}`
  } else {
    return `${formatDateToDDMM(startDate)} - ${formatDateToDDMM(weekDate)}`
  }
}