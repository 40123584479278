import React, { useEffect, useState } from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';

import '../styles/Stories.css';

import {NewsletterEntity} from "../entities/newsletter.entity";
import {NewsletterService} from "../services/newsletter.service";
import {useCookies} from "react-cookie";
import {monthNames} from "../helpers/DateHelper";

const AdminNewsletters = () => {
  const [newsletters, setNewsletters] = useState<NewsletterEntity[]>([]);
  const [{ profile }] = useCookies([ 'profile']);

  const fetchData = async () => {
    const data = await NewsletterService.list();
    setNewsletters(data);
  }

  const deleteNewsletter = async (id: number) => {
    if (window.confirm("Are you sure, you want to delete?")) {
      await NewsletterService.delete(id);
      fetchData();
    }
  }

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  return (
      <Container fluid>
        <Card className='shadow'>
          <Card.Header className='p-4'>
            <h2 style={{ display: 'inline-block' }} className='h-24'>Newsletter Archive</h2>
            <Button style={{ float: 'right' }} href="newsletter/add">Add New</Button>
          </Card.Header>
          <Card.Body>
            <Table hover>
              <thead>
              <tr style={{ textAlign: 'left' }}>
                <th>Newsletter Date</th>
                <th>PDF Filename</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {newsletters.map((n) =>
                <tr style={{ textAlign: 'left' }}>
                  <td>{monthNames[n.month - 1]}, {n.year}</td>
                  <td>{n.src}</td>
                  <td style={{ textAlign: 'right' }}>
                    <Button variant="danger" onClick={() => deleteNewsletter(n.id)}>Delete</Button>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
  );
};

export default AdminNewsletters;
