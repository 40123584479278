import React from "react";
import Input, {InputProps} from "./Input";
import {Form} from "react-bootstrap";

interface InputSelectProps extends InputProps {
  items: {
    text: string,
    value: string | number
  }[]
}

const InputSelect = (props: InputSelectProps) => {
  const { name, register, label, items, optional } = props;
  return <Input {...props}>
    <Form.Select aria-label={label} {...register(name, { required: !optional })} >
      {items.map(i => <option key={i.value} value={i.value}>{i.text}</option>)}
    </Form.Select>
  </Input>
}

export default InputSelect;