import React from "react";
import Input, {InputProps} from "./Input";
import {Form} from "react-bootstrap";

interface InputTextAreaProps extends InputProps {
  rows: number,
}

const InputTextArea = (props: InputTextAreaProps) => {
  const { name, register, rows, optional } = props;
  return <Input {...props}>
    <Form.Control as="textarea" rows={rows} {...register(name, { required: !optional })} />
  </Input>
}

export default InputTextArea;