import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { UsePlatforms } from '../hooks/UsePlatforms';
import {useCookies} from "react-cookie";

const Chart = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [PlatformTabs, selectedPlatform] = UsePlatforms();

  return (
    <Container fluid>
      {PlatformTabs}
      {selectedPlatform ? <Row>
        <Col>
          <Card className='shadow'>
            <Card.Body>
              <div>
                <div style ={{
                  position:"relative",
                  width: "100%",
                  height: "20px",
                  top: "20px",
                  background:"#ffffff"
                }}></div>
                <iframe style={{ height: '80vh' }} src={`${profile?.site.chartUrl}/${selectedPlatform}?:embed=y&:display_count=yes&publish=no&:showVizHome=no`} title="description"></iframe>
                <div style ={{
                  position:"relative",
                  width: "100%",
                  height: "35px",
                  bottom: "35px",
                  background:"#ffffff"
                }}></div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> : null}
    </Container>
  );
};

export default Chart;
