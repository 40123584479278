import React from "react";
import Input, {InputProps} from "./Input";
import {Form} from "react-bootstrap";

const InputText = (props: InputProps) => {
  const { name, register, optional } = props;
  return <Input {...props}>
    <Form.Control type="text" {...register(name, { required: !optional })} />
  </Input>
}

export default InputText;