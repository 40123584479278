import React, { useContext, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import '../styles/Header.css';
import { Col, Row } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContext';
import {useCookies} from "react-cookie";
import {useLocation} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {useViewportWidth} from "../hooks/UseViewportWidth";

const Header: React.FC = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const { isSmallScreen } = useViewportWidth();
  const expand = false;

  const isAdminSite = useMemo(() => location.pathname.split('/').indexOf('admin') > -1, [location]);
  const { site, isAdmin } = profile;
  const { slug, storyTitle } = site;

  const getIcon = (iconName: string, pathName: string, alwaysShow: boolean = false) => {
    const basePath = "/assets/icons/";
    let suffix = ''
    if (!alwaysShow && isSmallScreen) {
      suffix = 'Grayed Out'
    } else if (window.location.pathname === pathName) {
      suffix = "Active";
    } else {
      suffix = 'Inactive';
    }

    return `${basePath}${iconName} ${suffix}.png`; 
  }

  const links: {
    href: string,
    title: string,
    icon?: string,
    alwaysShow?: boolean
  }[] = isAdminSite
      ? [
          { href: `/${slug}/admin`, title: storyTitle || 'Stories' },
          { href: `/${slug}/admin/brands`, title: 'Brands in Culture' },
          { href: `/${slug}/admin/newsletters`, title: 'Newsletter Archive' },
        ]
      : [
          { href: `/${slug}`, title: storyTitle || 'Stories', icon:'Latest Stories', alwaysShow: true },
          { href: `/${slug}/brands`, title: 'Brands in Culture', alwaysShow: true },
          { href: `/${slug}/music`, title: 'Music Charts' },
          { href: `/${slug}/chart`, title: 'Influencer Charts' },
          { href: `/${slug}/db`, title: 'Influencer DB' },
          { href: `/${slug}/newsletters`, title: 'Newsletter Archive', icon:'Newsletter', alwaysShow: true },
        ]

  return (
    <>
        <Navbar expand={expand} style={{ backgroundColor: '#292e5f' }}>
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} style={{ color: '#fff' }} />
            <Navbar.Brand href="/" style={{ color: '#fff' }}>
              <img src={'/assets/icons/logo white.png'} alt='Trumi' />
            </Navbar.Brand>
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            >
              <Offcanvas.Header closeButton>
                {isAdmin
                  ? <Nav.Link href={ isAdminSite ? `/${slug}` : `/${slug}/admin` } disabled={isSmallScreen}>
                      <BiArrowBack size={24} />
                      <span className='px-3' >
                        {isAdminSite ? 'Main Site' : 'Admin Site'}
                        {isSmallScreen ? '*' : ''}
                      </span>
                    </Nav.Link>
                  : null
                }
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {links.map(({ href, title, alwaysShow, icon }, index) =>
                    <Nav.Link key={index} href={href} disabled={!alwaysShow && isSmallScreen}>
                      {!isAdminSite ? <img src={getIcon(icon || title, href, alwaysShow)} alt={title} /> : null}
                      <span 
                        className={`px-3 ${window.location.pathname === href ? 'active' : ''}`}>
                          {title}{!alwaysShow && isSmallScreen ? '*' : ''}
                      </span>
                    </Nav.Link>
                  )}
                </Nav>
                {isSmallScreen
                  ? <Row>
                      <Col className='px-4 mx-4 my-2 small'>* Only available on desktop</Col>
                    </Row>
                  : null
                }
              </Offcanvas.Body>
              <Nav.Link className='py-4 border-top'>
                <span className={`px-3`} onClick={logout}>
                  Logout
                </span>
              </Nav.Link>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
    </>
  );
};

export default Header;
