import React, { useEffect, useState } from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';

import '../styles/Stories.css';

import {BrandEntity} from "../entities/brand.entity";
import {BrandService} from "../services/brand.service";
import {useCookies} from "react-cookie";

const AdminBrands = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [brands, setBrands] = useState<BrandEntity[]>([]);

  const fetchData = async () => {
    const data = await BrandService.list();
    setBrands(data);
  }

  const deleteBrand = async (id: number) => {
    if (window.confirm("Are you sure, you want to delete?")) {
      await BrandService.delete(id);
      fetchData();
    }
  }

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  return (
    <Container fluid>
      <Card className='shadow'>
        <Card.Header className='p-4'>
          <h2 style={{ display: 'inline-block' }} className='h-24'>Brands In Culture</h2>
          <Button style={{ float: 'right' }} href="brand/add">Add New</Button>
        </Card.Header>
        <Card.Body>
          <Table hover>
            <thead>
            <tr style={{ textAlign: 'left' }}>
              <th>PDF Title</th>
              <th>PDF Filename</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {brands.map((b) =>
              <tr style={{ textAlign: 'left' }}>
                <td>{b.title}</td>
                <td>{b.src}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button variant="danger" onClick={() => deleteBrand(b.id)}>Delete</Button>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminBrands;
