import React from 'react';
import {Button, ButtonGroup, Card, Col, Container, Form, Row} from "react-bootstrap";
import {useForm, SubmitHandler } from "react-hook-form";
import {useCookies} from "react-cookie";
import Input from "../components/Input";
import {useNavigate} from "react-router-dom";
import {NewsletterWithFile} from "../entities/newsletter.entity";
import {NewsletterService} from "../services/newsletter.service";
import InputSelect from "../components/InputSelect";
import {monthNames, years} from "../helpers/DateHelper";

const AdminNewsletterAdd = () => {
    const [{ profile }] = useCookies([ 'profile']);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors},
    } = useForm<NewsletterWithFile>({
        defaultValues: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1
        }
    })

    const onSubmit: SubmitHandler<NewsletterWithFile> = async (newsletter) => {
        await NewsletterService.create(newsletter);
        navigate(`/${profile?.site.slug}/admin/newsletters`);
    }

    return <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Card className='shadow'>
                <Card.Header className='p-3'>
                    <h2 className='h-24'>Add Newsletter</h2>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <InputSelect name="month" register={register} error={errors.month} label="Month" items={
                                monthNames.map((text, i) => ({ text, value: i + 1 }))
                            } />
                        </Col>
                        <Col>
                            <InputSelect name="year" register={register} error={errors.year} label="Year" items={
                                years.map((value) => ({ text: value.toString(), value }))
                            } />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Input name="files" label="Pdf file" register={register} error={errors.pdf}>
                                <Form.Control type="file" accept=".pdf" {...register("pdf", { required: true })} />
                            </Input>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className='p-3'>
                    <ButtonGroup className='float-end'>
                        <Button variant="secondary" href={`/${profile?.site.slug}/admin`}>Cancel</Button>
                        <Button variant="primary" type="submit">
                            Publish
                        </Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </Form>
    </Container>
}

export default AdminNewsletterAdd;