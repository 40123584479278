import React, { useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import ViewPdf from '../components/ViewPdf';
import {useCookies} from "react-cookie";
import {apiURL} from "../common/config";

function Brand() {
  const { id } = useParams();
  const [{ profile }] = useCookies([ 'profile']);
  const [url, setUrl] = useState('');

 useEffect(() => {
    if (!profile) {
      return;
    }

    setUrl(`${apiURL}/brand/view/${id}`);
  }, [id, profile])

  return <ViewPdf doc={url} />
}

export default Brand;
