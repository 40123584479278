import Papa from "papaparse";

export const readCSV = (url: string, onComplete: (data: any[]) => void) => {
  Papa.parse(url, {
    header: true,
    dynamicTyping: true,
    download: true,
    transformHeader: (header) => header.trim(),
    complete: (results) => onComplete(results.data as unknown as any[])
  });
}