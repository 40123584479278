import {AxiosService} from "./axios.service";
import {BrandEntity, BrandWithFile} from "../entities/brand.entity";

export class BrandService {
    static async list() {
        const response = await AxiosService.get<BrandEntity[]>('/brand/list');
        return response || [];
    }

    static async create(brand: BrandWithFile) {
        const formData = new FormData();

        formData.append("thumbnail", brand.thumbnail[0],  brand.thumbnail[0].name)
        formData.append("pdf", brand.pdf[0],  brand.pdf[0].name)

        formData.append("brand", JSON.stringify(brand));

        return await AxiosService.post('/brand', formData, "multipart/form-data");
    }

    static async delete(id: number) {
        await AxiosService.delete(`/brand/${id}`);
    }
}