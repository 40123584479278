import React, { useEffect, useState } from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';

import '../styles/Stories.css';
import { sortByYearAndMonth } from '../helpers/ArrayHelper';
import {StoryWithCategory} from "../entities/story.entity";
import {StoryService} from "../services/story.service";
import {useCookies} from "react-cookie";
import {monthNames} from "../helpers/DateHelper";

const AdminStories = () => {
  const [{ profile }] = useCookies([ 'profile']);
  const [stories, setStories] = useState<StoryWithCategory[]>([]);

  const fetchData = async () => {
    const stories = await StoryService.list();
    const sortStories = sortByYearAndMonth(stories);
    setStories(sortStories);
  }

  const deleteStory = async (id: number) => {
    if (window.confirm("Are you sure, you want to delete?")) {
      await StoryService.delete(id);
      fetchData();
    }
  }

  useEffect(() => {
    if (!profile) {
      return;
    }

    fetchData();
  }, [profile])

  return (
    <Container fluid>
      <Card className='shadow'>
        <Card.Header className='p-4'>
          <h2 style={{ display: 'inline-block' }} className='h-24'>{profile.site.storyTitle}</h2>
          <Button className='float-end' href={`/${profile?.site.slug}/admin/story/add`}>Add New</Button>
        </Card.Header>
        <Card.Body>
          <Table hover>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                <th>Title</th>
                <th>Category</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {stories.map((s) =>
              <tr style={{ textAlign: 'left' }}>
                <td>{s.title}</td>
                <td>{s.category.title}</td>
                <td>{monthNames[s.month - 1]}, {s.year}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button variant="danger" onClick={() => deleteStory(s.id)}>Delete</Button>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminStories;
