import {AxiosService} from "./axios.service";
import {UserEntity} from "../entities/user.entity";

export class AuthService {
    static async login(data: { username: string, password: string }) {
        return AxiosService.post<{ access_token: string }>('/auth/login', data);
    }

    static async logout() {
        return AxiosService.post<void>('/auth/logout');
    }

    static async profile() {
        return AxiosService.get<UserEntity>('/auth/profile');
    }
}